import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import vLibrary from '@iconify/icons-ic/video-library';
import icDashboard from '@iconify/icons-ic/twotone-dashboard';
import IcGroup from '@iconify/icons-ic/twotone-group';
import IcSettings from '@iconify/icons-ic/twotone-settings';
import IcWithdraw  from '@iconify/icons-ic/sharp-request-page';
import IcCommission from '@iconify/icons-ic/round-percentage';
import IcHelp from '@iconify/icons-ic/twotone-help';
import ICHELOIFJDO from '@iconify/icons-ic/all-inclusive';
import ICONTOOL from '@iconify/icons-ic/kitchen';
import ICGUILD from '@iconify/icons-ic/book';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Affiliates';

  constructor(private configService: ConfigService,
              private styleService: StyleService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService) {
                console.log("affiliate code length should be 5 to 10 characters long.");
                
    Settings.defaultLocale = this.localeId;

    // this.checkUserInactive();

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr';
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));


    this.navigationService.items = [
        {
        type: 'link',
        label: 'Dashboard',
        route: '/',
        icon: icDashboard,
      },
      {
        type: 'link',
        label: 'Users',
        route: '/users',
        icon: IcGroup,
      },
      {
        type: 'link',
        label: 'Order Commission',
        route: '/order-commission',
        icon: IcCommission,
      },
      // {
      //   type: 'link',
      //   label: 'Total Withdrawal',
      //   route: '/withdrawal',
      //   icon: IcMoney,
      // },
      {
        type: 'link',
        label: 'Withdraw Request',
        route: '/commission-withdrawal',
        icon: IcWithdraw,
      },
      {
        type : 'link',
        label: 'Plans',
        route : '/plans',
        icon : ICHELOIFJDO,
        badge : { value : 'NEW', bgClass : 'bg-amber', textClass : 'text-blackColor font-bold'}
      },
      // {
      //   type : 'dropdown',
      //   label : 'Announcement',
      //   // route : '/announcement',
      //   icon : announcement,
      //   badge : { value : 'NEW', bgClass : 'bg-amber', textClass : 'text-blackColor font-bold'},
      //   children : [
      //     {
      //       type : 'link',
      //       label: 'Social Media Graphics',
      //       route : '/how-it-works/tutorial',
      //       icon : ICHELOIFJDO
      //     },
      //     {
      //       type : 'link',
      //       label: 'Affiliate Toolkit',
      //       route : '/how-it-works/tutorial',
      //       icon : ICHELOIFJDO
      //     },
      //   ]
      // },

      // {
      //   type : 'dropdown',
      //   label: 'Affiliate Guidelines',
      //   // route : '/announcement',
      //   // badge : { value : 'NEW', bgClass : 'bg-amber', textClass : 'text-blackColor font-bold'},
      //   icon : ICONTOOL,
      //   children: [
      //     {
      //       type : 'link',
      //       label: 'Guidelines',
      //       route : '/guidelines',
      //       // badge : { value : 'NEW', bgClass : 'bg-amber', textClass : 'text-blackColor font-bold'},
      //       // icon : ICONTOOL
      //     },
      //     {
      //       type : 'link',
      //       label: 'Announcements',
      //       route : '/announcement',
      //       // badge : { value : 'NEW', bgClass : 'bg-amber', textClass : 'text-blackColor font-bold'},
      //       // icon : ICONTOOL
      //     },
      //     {
      //       type : 'link',
      //       label: 'Affiliate Toolkit',
      //       route : '/announcement',
      //       // badge : { value : 'NEW', bgClass : 'bg-amber', textClass : 'text-blackColor font-bold'},
      //       // icon : ICONTOOL
      //     },
      //   ]
      // },

      {
        type : 'link',
        label: 'Affiliate Guidelines',
        route : '/guidelines',
        badge : { value : 'NEW', bgClass : 'bg-amber', textClass : 'text-blackColor font-bold'},
        icon : ICGUILD
      },

      {
        type : 'link',
        label: 'Affiliate Toolkit',
        route : '/announcement',
        badge : { value : 'NEW', bgClass : 'bg-amber', textClass : 'text-blackColor font-bold'},
        icon : ICONTOOL
      },
      {
        type : 'link',
        label: 'How it works',
        route : '/how-it-works/tutorial',
        icon : vLibrary
      },
      // {
      //   type: 'dropdown',
      //   label: 'How it works',
      //   icon: icLayers,
      //   children : [
      //     {
      //       type : 'link',
      //       label: 'Tutorials',
      //       route : '/how-it-works/tutorial',
      //       icon : ICHELOIFJDO
      //     },
      //     // {
      //     //   type : 'link',
      //     //   label: 'Content',
      //     //   route : '/how-it-works/content',
      //     //   icon : ICHELOIFJDO,
      //     //   badge : { value : 'NEW', bgClass : 'bg-amber', textClass : 'text-blackColor font-bold'}
      //     // },
      //   ]
      // },
      // {
      //   type: 'link',
      //   label: `FAQ's`,
      //   route: '/faq',
      //   icon: Question,
      //   badge : { value : 'NEW', bgClass : 'bg-amber', textClass : 'text-blackColor font-bold'}
      // },
      {
        type: 'link',
        label: 'Settings',
        route: '/settings',
        icon: IcSettings,
      },
      {
        type: 'link',
        label: 'Help',
        route: '/help',
        icon: IcHelp,
      }

    ];
  }
}

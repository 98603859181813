import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import icBlock from "@iconify/icons-ic/sharp-block";
import { fadeInUp400ms } from "src/@vex/animations/fade-in-up.animation";
import { LocalStoreService } from "src/@vex/services/local-store.service";

@Component({
    selector: "vex-affiliate-user-blocked",
    templateUrl: "./affiliate-user-blocked.component.html",
    styleUrls: ["./affiliate-user-blocked.component.scss"],
    animations: [fadeInUp400ms],
})
export class AffiliateUserBlockedComponent implements OnInit {
    blockIcon = icBlock;
    userInform;
    constructor(private store: LocalStoreService, private router: Router) {
        // localStorage.clear();
        if (
            localStorage.getItem("loggedUser") ||
            localStorage.getItem("spInform")
        ) {
            if (localStorage.getItem("loggedUser")) {
                const userSpInform = {
                    firstName: JSON.parse(localStorage.getItem("loggedUser"))
                        ?.firstName,
                    lastName: JSON.parse(localStorage.getItem("loggedUser"))
                        ?.lastName,
                    country: JSON.parse(localStorage.getItem("loggedUser"))
                        ?.country,
                };
                localStorage.setItem("spInform", JSON.stringify(userSpInform));
                localStorage.removeItem("loggedUser");
            }

            this.userInform = JSON.parse(localStorage.getItem("spInform"));
            console.log("this.userInform", this.userInform);
        }else{
          this.router.navigate(['/login'])
        }
    }

    ngOnInit(): void {}
}

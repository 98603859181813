import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { WithdrawalModel } from '../interfaces/withdrawal.model';
import icClose from '@iconify/icons-ic/twotone-close';
import icPerson from '@iconify/icons-ic/twotone-person';
import icAdd from '@iconify/icons-ic/twotone-add';
import {AuthServiceLocal} from '../../../../@vex/services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Messages} from '../../../../@vex/utils/message';



@Component({
  selector: 'vex-customer-create-update',
  templateUrl: './withdrawal-request.component.html',
  styleUrls: ['./withdrawal-request.component.scss']
})
export class WithdrawalRequestComponent implements OnInit {

  static id = 100;
  form: FormGroup;
  mode: 'create' | 'update' = 'create';
  icClose = icClose;
  icPerson = icPerson;
  icAdd = icAdd;
  message = Messages;
  totalAmountAvailable;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private dialogRef: MatDialogRef<WithdrawalRequestComponent>,
              private fb: FormBuilder,
              private auth: AuthServiceLocal,
              private snackbar: MatSnackBar){
  }

  ngOnInit() {
   // if (this.defaults) {
   //    this.mode = 'update';
   //  } else {
   //    this.defaults = {} as WithdrawalModel;    
   //  }

   this.form = this.fb.group({
      amount: ['',  [Validators.required, Validators.max(this.defaults.availableAmount)]],
      remarks: ['', [Validators.required, Validators.maxLength(30)]],
    });
  }

  /**
   * On form submit
   * */
  save() {
    if (this.form.invalid){
        return false;
    } else {
        if (this.mode === 'create') {
            this.requestWithdrawal();
        } else if (this.mode === 'update') {
            // this.updateNgo();
        }
    }
  }

  isCreateMode() {
      return this.mode === 'create';
  }

  isUpdateMode() {
      return this.mode === 'update';
  }

  /**
   * function call when submit request
   */
  requestWithdrawal() {
    const postData = {
        amount: Number(this.form.value.amount),
        remarks: this.form.value.remarks,
        payment_type: 1
    };
    this.auth.requestWithdrawal(postData).subscribe((response: any) => {
        if (response.response === 1){
            // this.store.setItem('loggedUser', response.data);
            this.snackbar.open(response.message, '');
        } else{
            this.snackbar.open(response.message, '');
        }
    });
    this.dialogRef.close(postData);
  }

}

import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {fadeInUp400ms} from '../../../@vex/animations/fade-in-up.animation';
import {AuthServiceLocal} from '../../../@vex/services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LocalStoreService} from '../../../@vex/services/local-store.service';
import {country, Pattern} from '../../../@vex/utils/systemEnums';
import {Messages} from '../../../@vex/utils/message';
import icImage from '@iconify/icons-ic/twotone-image';
import icClose from '@iconify/icons-ic/close';
import icUpload from '@iconify/icons-fa-solid/upload';

@Component({
  selector: 'vex-register',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class DocumentUploadComponent implements OnInit, AfterViewInit {
  year = new Date().getFullYear();
  country = country;
  icImage = icImage;
  icUpload = icUpload;
  form: FormGroup;
  pattern = Pattern;
  message = Messages;
  docListForGhana = ['Ghana card', 'Passport', 'Drivers License'];
  docListForNigeria = ['Passport', 'National Identification Number (NIN)', 'Driver’s License'];
  docImage:any = {
    front: '',
    back: ''
  };
  docImageUrl:any = {
    front: '',
    back: ''
  };
  docUrl:any = {
    front: '',
    back: ''
  };
  isDocumentUploaded;
  docList = [];
  user;

  icClose = icClose;

  constructor(private router: Router, private cd: ChangeDetectorRef, private fb: FormBuilder,
              private snackbar: MatSnackBar, private auth: AuthServiceLocal,
              private store: LocalStoreService) {

    this.user = this.auth.getUser();
    console.log("this.user===>", this.user);
    if(this.user.status == 2){
      this.router.navigate(['/rejected-user']);
    }else{
      if (this.user.isDocumentUploaded === 1) {
        this.isDocumentUploaded = true;
      } else {
        this.isDocumentUploaded = false;
      }
  
      this.setDocumentList();
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      docType: ['', Validators.required],
      docNumber: ['', Validators.required],
      frontImage: '',
      backImage: ''
    });
  }

  setDocumentList() {
    if (this.user.country.toLowerCase() === country.Ghana) {
      this.docList = this.docListForGhana;
    } else if (this.user.country.toLowerCase() === country.Nigeria) {
      this.docList = this.docListForNigeria;
    }
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  logout() {
    this.store.clear();
    this.router.navigate(['/login']);
  }

  checkSelected(value) {
    // console.log(value);
  }

  removeFrontImage(){
    this.docImageUrl.front = '';
    this.docImage.front = '';
    this.docUrl.front = '';
    this.form.get('frontImage').setValue('');
  }

  removeBackImage(){
    this.docImageUrl.back = '';
    this.docImage.back = '';
    this.docUrl.back = '';
    this.form.get('backImage').setValue('');
  }
  /**
   *  For get image
   * */
  // readUrl(event, type) {
  //   if (event.target.files[0]) {
  //     const file = event.target.files[0];
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     if (type === 'front') {
  //       this.docImage.front = file;
  //       reader.onload = (_event) => {
  //         this.docImageUrl.front = reader.result.toString();
  //       };
  //     } else if (type === 'back') {
  //       this.docImage.back = file;
  //       reader.onload = (_event) => {
  //         this.docImageUrl.back = reader.result.toString();
  //       };
  //     }
  //   }
  // }

  readUrl(event: Event, type: string): void {
    const input = event.target as HTMLInputElement;
  
    if (input?.files?.[0]) {
      const file = input.files[0];
      // Regular expression for common image MIME types
      const imageTypePattern = /^image\/(jpeg|png|webp|)$/;
  
      // Validate file type
      if (!imageTypePattern.test(file.type)) {
        this.snackbar.open('Invalid file type. Please select an image (JPEG, PNG or WEBP).', 'OKAY', {duration : 5000});
        input.value = ''; // Clear the input
        return;
      }
  
      // Process valid image files
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      if (type === 'front') {
        this.docImage.front = file;
        reader.onload = () => {
          this.docImageUrl.front = reader.result?.toString() || '';
        };
      } else if (type === 'back') {
        this.docImage.back = file;
        reader.onload = () => {
          this.docImageUrl.back = reader.result?.toString() || '';
        };
      }
    } else {
      this.snackbar.open('No file selected.', 'OKAY', {duration : 5000});
    }
  }

  /**
   * function used to document-upload user
   */
  uploadDocumentImage() {
    if (this.form.invalid) {
      return false;
    } else {
      // front image
      const formData = new FormData();
      formData.append('image', this.docImage.front);
      this.auth.uploadDocumentImage(formData).subscribe((response: any) => {
        if (response.response === 1){
          this.docUrl.front = response.data.url;
        } else{
          this.snackbar.open(response.message);
        }

        // back image
        const formData1 = new FormData();
        formData1.append('image', this.docImage.back);
        this.auth.uploadDocumentImage(formData1).subscribe((res: any) => {
          if (res.response === 1){
            this.docUrl.back = res.data.url;
          } else{
            this.snackbar.open(res.message);
          }
          this.uploadDocuments();
        });
      });
    }
  }

  /**
   * function used to document-upload user
   */
  uploadDocuments() {
    this.isDocumentUploaded = false;
    const postData = {
      docType: this.form.value.docType,
      docNumber: this.form.value.docNumber,
      docFrontUrl: this.docUrl.front,
      docBackUrl: this.docUrl.back,
    };
    this.auth.uploadDocuments(postData).subscribe((response: any) => {
      if (response.response === 1){
        // this.store.setItem('loggedUser', response.data);
        this.snackbar.open(response.message);
        this.isDocumentUploaded = true;
        this.user.isDocumentUploaded = 1;
        this.store.setItem('loggedUser', this.user);
      } else{
        this.snackbar.open(response.message);
        this.isDocumentUploaded = false;
      }
    });
  }
}

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { VexModule } from "../@vex/vex.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CustomLayoutModule } from "./custom-layout/custom-layout.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { UserIdleModule } from "angular-user-idle";
import { TokenInterceptorInterceptor } from "src/@vex/services/token-interceptor.interceptor";
import { AffiliateUserBlockedComponent } from "./affiliate-user-blocked/affiliate-user-blocked.component";
import { MatIconModule } from "@angular/material/icon"; 
import { IconModule } from "@visurel/iconify-angular"; 
// import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
// import { getFirestore, provideFirestore } from '@angular/fire/firestore'; 
// import { getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from "src/environments/environment";
// import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
// import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { WithdrawalModule } from "./pages/withdrawal/withdrawal.module";
import { FaqModule } from "./pages/faq/faq.module";
import { BankDetailCreateUpdateModule } from "./pages/admin-profile/bank-detail-create-update/bank-detail-create-update.module";
import { DocumentUploadModule } from "./document-upload-layout/document-upload/document-upload.module";
import { DocumentUploadLayoutModule } from "./document-upload-layout/document-upload-layout.module";
import { StripHtmlModule } from "src/@vex/pipes/strip-html/strip-html.module";
import { DateTokensModule } from "src/@vex/pipes/date-tokens/date-tokens.module";
import { HighlightModule } from "src/@vex/components/highlight/highlight.module";
@NgModule({
    declarations: [AppComponent, AffiliateUserBlockedComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HighlightModule,
        StripHtmlModule,
        MatIconModule,
        DateTokensModule,
        IconModule,
        FaqModule,
        BankDetailCreateUpdateModule,
        WithdrawalModule,
        DocumentUploadLayoutModule,
        DocumentUploadModule,
        HttpClientModule,
        UserIdleModule.forRoot({
            idle: 480,
            timeout: 2,
            ping: 30,
        }),
        // Vex
        VexModule,
        CustomLayoutModule,
        FlexLayoutModule,

        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule, // Add modules as needed
        
        // firebase
        // provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        // provideFirestore(() => getFirestore()), 
        // provideAuth(() => getAuth()),

 
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorInterceptor,
            multi: true,
        },
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {}

import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import Icclose from "@iconify/icons-ic/close";

@Component({
    selector: "vex-image-viewer-dialog",
    templateUrl: "./image-viewer-dialog.component.html",
    styleUrls: ["./image-viewer-dialog.component.scss"],
    animations: [
        trigger("fadeIn", [
            transition(":enter", [
                style({ opacity: 0 }),
                animate("500ms ease-in", style({ opacity: 1 })),
            ]),
        ]),

        trigger("slideInUp", [
            transition(":enter", [
                style({ transform: "translateY(100%)", opacity: 0 }),
                animate(
                    "500ms ease-out",
                    style({ transform: "translateY(0)", opacity: 1 })
                ),
            ]),
        ]),
    ],
})
export class ImageViewerDialogComponent implements OnInit {
    image: string = "";
    close = Icclose;

    constructor(
        public _dialogRef: MatDialogRef<ImageViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {}

    ngOnInit(): void {
        this.image = this._data.imageSrc;
    }
}

import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  // regExp = /^.*(share.getcloudapp.com\/|v\/|u\/\w\/|embed|)([^#\&\?]*).*/;
  regExp = /^.*(share.getcloudapp.com\/)([^#\&\?]*).*/;
  constructor(private sanitizer: DomSanitizer) { }

  transform(url) {
    const match = url.match(this.regExp);
    if (match) {
      url = this.sanitizer.bypassSecurityTrustResourceUrl('https://share.getcloudapp.com/' + match[2] + '?embed=true');
      this.sanitizer.sanitize(SecurityContext.URL, url);
      return url;
    } else {
      return 'error';
    }
  }

}

export const Messages = {
    field: {
        required: 'Field is required.',
    },
    name: {
        required: 'Name is required.',
    },
    firstName: {
        required: 'First Name is required.',
    },
    lastName: {
        required: 'Last Name is required.'
    },
    password: {
        required: 'Password is required.',
        pattern: 'Password should contain Min. 6 characters, Minimum of 1 Lowercase, 1 Uppercase and 1 digit'
    },
    newPassword: {
        required: 'New Password is required.',
        pattern: 'Password should contain Min. 6 characters, Minimum of 1 Lowercase, 1 Uppercase and 1 digit'
    },
    oldPassword: {
        required: 'Old Password is required.',
        pattern: 'Password should contain Min. 6 characters, Minimum of 1 Lowercase, 1 Uppercase and 1 digit'
    },
    confirmPassword: {
        required: 'Confirm Password is required.',
        pattern: 'Confirm Password do not match.'
    },
    linkInvalidMessage : 'Invalid URL format',
    email : {
        required: 'Email is required.',
        pattern: 'Please enter valid email.',
        invalid : 'Email address is invalid',
        exist: 'Email is Already Exist'
    },
    confirmEmail : {
        required: 'Confirm Email is required.',
        invalid : 'Email address is invalid',
        pattern: 'Email does not match.'
    },
    facebook : {
        pattern: 'Please input url links to your social media profile(s)'
    },
    instagram : {
        pattern: 'Please input url links to your social media profile(s)'
    },
    youtube : {
        pattern: 'Please input url links to your social media profile(s)'
    },
    twitter : {
        pattern: 'Please input url links to your social media profile(s)'
    },
    tiktok : {
        pattern: 'Please input url links to your social media profile(s)'
    },
    amount : {
        required: 'Amount is required.',
        pattern: 'Amount Should be less than Available Amount'
    },
    phoneNumber : {
        required: 'Phone Number is required.',
        minLengthForGhana: 'Phone No. min length is 9 digit',
        minLengthForNigeria: 'Phone No. min length is 10 digit',
        pattern: 'Please enter valid Number',
        confirmPhoneNumber: 'Phone Number does not match.',
        exist: 'Phone Number is Already Exist'
    },
    phoneCode : {
        required: 'Phone Code is required.',
        confirmPhoneCode: 'Phone Code does not match.',
    },
    country: {
        required: 'Country is required.',
    },
    subject : {
        required: 'Subject is required.',
    },
    message : {
        required: 'Message is required.',
        maxLength: 'Message limit should not exceed from 2000 letters.',
    },
    noDataFound: 'No Data Found',
    somethingWentWrong: 'Something Went Wrong',
    noBankAdded: 'No Bank Detail Added Yet',
    noMobileMoneyAdded : 'No Mobile Money Added Yet',
    urlCopied: 'Copied to clipboard',
};

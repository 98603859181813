import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ThrowStmt } from '@angular/compiler';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Route, Router } from '@angular/router';

@Injectable()

export class TokenInterceptorInterceptor implements HttpInterceptor {

  constructor(
    private _messageBar : MatSnackBar,
    private _router : Router
  ) {}

  intercept(request: HttpRequest<unknown>,next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log('Interceptor triggered');
    
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Check for 402 status code
        if (error.status === 402) {
          // this._messageBar.open("Your account is blocked by admin. Please reach out to support@phlenu.com", 'OKAY', {duration:3000});
          // localStorage.clear();
          const userSpInform = {
            firstName : JSON.parse(localStorage.getItem('loggedUser')).firstName,
            lastName : JSON.parse(localStorage.getItem('loggedUser')).lastName,
            country : JSON.parse(localStorage.getItem('loggedUser')).country,
          }
          localStorage.setItem('spInform', JSON.stringify(userSpInform));
          localStorage.removeItem('loggedUser')
          this._router.navigate(['/affiliate-blocked']);
        }else if(error.status === 403){
          // this._messageBar.open("Your account is blocked by admin. Please reach out to support@phlenu.com", 'OKAY', {duration:3000});
          // localStorage.clear();
          const userSpInform = {
            firstName : JSON.parse(localStorage.getItem('loggedUser')).firstName,
            lastName : JSON.parse(localStorage.getItem('loggedUser')).lastName,
            country : JSON.parse(localStorage.getItem('loggedUser')).country,
          }
          localStorage.setItem('spInform', JSON.stringify(userSpInform));
          localStorage.removeItem('loggedUser')
          this._router.navigate(['/rejected-user']);
        }

        // Pass the error to the caller
        return throwError(() => error);
      })
    );
  }

}

import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
} from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { filter } from "rxjs/operators";
import { WithdrawalModel } from "./interfaces/withdrawal.model";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { TableColumn } from "../../../@vex/interfaces/table-column.interface";
import icEdit from "@iconify/icons-ic/twotone-edit";
import icDelete from "@iconify/icons-ic/twotone-delete";
import icSearch from "@iconify/icons-ic/twotone-search";
import icAdd from "@iconify/icons-ic/twotone-add";
import icFilterList from "@iconify/icons-ic/twotone-filter-list";
import icDolor from "@iconify/icons-ic/twotone-attach-money";
import { SelectionModel } from "@angular/cdk/collections";
import icMoreHoriz from "@iconify/icons-ic/twotone-more-horiz";
import icFolder from "@iconify/icons-ic/twotone-folder";
import { fadeInUp400ms } from "../../../@vex/animations/fade-in-up.animation";
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatFormFieldDefaultOptions,
} from "@angular/material/form-field";
import { stagger40ms } from "../../../@vex/animations/stagger.animation";
import { FormControl, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthServiceLocal } from "../../../@vex/services/auth.service";
import { ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { WithdrawalRequestComponent } from "./withdrawal-request/withdrawal-request.component";
import { country, currency, status } from "../../../@vex/utils/systemEnums";
import { fadeInRight400ms } from "src/@vex/animations/fade-in-right.animation";
import { scaleIn400ms } from "src/@vex/animations/scale-in.animation";

@UntilDestroy()
@Component({
    selector: "vex-aio-table",
    templateUrl: "./withdrawal.component.html",
    styleUrls: ["./withdrawal.component.scss"],
    animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms, stagger40ms],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: "standard",
            } as MatFormFieldDefaultOptions,
        },
    ],
})
export class WithdrawalComponent implements OnInit, AfterViewInit {
    status = status;
    layoutCtrl = new FormControl("boxed");
    AdminDetail;
    currency = currency;
    country = country;

    /**
     * Simulating a service with HTTP that returns Observables
     * You probably want to remove this and do all requests in a service with HTTP
     */
    subject$: ReplaySubject<WithdrawalModel[]> = new ReplaySubject<
        WithdrawalModel[]
    >(1);
    data$: Observable<WithdrawalModel[]> = this.subject$.asObservable();
    customers: WithdrawalModel[];

    @Input()
    columns: TableColumn<WithdrawalModel>[] = [
        { label: "Amount", property: "amount", type: "text", visible: true },
        { label: "Remarks", property: "remarks", type: "text", visible: true },
        {
            label: "Admin Approved",
            property: "isAdminApproved",
            type: "text",
            visible: true,
        },
        {
            label: "Payment Status",
            property: "paymentStatus",
            type: "text",
            visible: true,
        },
        {
            label: "Requested Date",
            property: "createdDate",
            type: "text",
            visible: true,
        },
        // { label: 'Withdrawal Date', property: 'withdrawalDate', type: 'text', visible: true },
        {
            label: "Actions",
            property: "actions",
            type: "button",
            visible: false,
        },
    ];
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 20, 50];
    dataSource: MatTableDataSource<WithdrawalModel> | null;
    selection = new SelectionModel<WithdrawalModel>(true, []);
    searchCtrl = new FormControl();

    icDolor = icDolor;
    icSearch = icSearch;
    icFolder = icFolder;
    totalAmountAvailable;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private dialog: MatDialog,
        private auth: AuthServiceLocal,
        private router: ActivatedRoute,
        private snackbar: MatSnackBar,
        private cd: ChangeDetectorRef
    ) {
        this.AdminDetail = this.auth.getUser();
    }

    get visibleColumns() {
        return this.columns
            .filter((column) => column.visible)
            .map((column) => column.property);
    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    /**
     * Get All Ngo list
     */
    getData() {
        return this.auth.getWithdrawalList().subscribe((response: any) => {
            if (response.response === 1) {
                this.subject$.next(response.data.withdrawalList);
            } else {
                // this.snackbar.open(response.message, '');
            }
        });
    }

    ngOnInit() {
        this.getMyAmount();
        this.getData();
        this.dataSource = new MatTableDataSource();
        this.data$
            .pipe(filter<WithdrawalModel[]>(Boolean))
            .subscribe((customers) => {
                this.customers = customers;
                this.dataSource.data = customers;
            });

        this.searchCtrl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((value) => this.onFilterChange(value));
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.cd.detectChanges();
    }

    /**
     * Get Total Amount
     */
    getMyAmount() {
        this.auth.getTotalAmount().subscribe((response: any) => {
            if (response.response === 1) {
                this.totalAmountAvailable = response.data.totalEarnedAmt;
            } else {
                this.snackbar.open(response.message, "");
            }
        });
    }

    /**
     *  Open Withdrawal dialog form
     * */
    openWithdrawalPopup() {
        this.dialog
            .open(WithdrawalRequestComponent, {
                data: {
                    availableAmount: this.totalAmountAvailable,
                },
            })
            .afterClosed()
            .subscribe((res: any) => {
                if (res) {
                    this.getData();
                    this.getMyAmount();
                }
            });
    }

    onFilterChange(value: string) {
        if (!this.dataSource) {
            return;
        }
        value = value.trim();
        value = value.toLowerCase();
        this.dataSource.filter = value;
    }
}

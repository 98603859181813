<form (ngSubmit)="save()" [formGroup]="form" >
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

        <h2 *ngIf="mode=='update'"
            class="headline m-0"
            fxFlex="auto">Request Withdrawal</h2>
        <h2 *ngIf="mode=='create'"
            class="headline m-0"
            fxFlex="auto">Request Withdrawal</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto full-width-field">
                <mat-label>Amount</mat-label>
                <input cdkFocusInitial formControlName="amount" [max]="defaults.availableAmount" matInput required>
                <mat-error *ngIf="form.get('amount').hasError('required')">{{message.amount.required}}</mat-error>
                <mat-error *ngIf="form.get('amount').hasError('max')">{{message.amount.pattern}}</mat-error>
            </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto">
                <mat-label>Remarks</mat-label>
                <textarea formControlName="remarks" [maxlength]="30" matInput required rows="5"></textarea>
                <mat-error *ngIf="form.get('remarks').hasError('required')">{{message.field.required}}</mat-error>
            </mat-form-field>
        </div> 

    </mat-dialog-content> 

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">Cancel</button>
        <button *ngIf="isCreateMode()" color="primary" mat-button type="submit" [disabled]="form.invalid">Send Request</button>
        <button *ngIf="isUpdateMode()" color="primary" mat-button type="submit" [disabled]="form.invalid">Send Request</button>
    </mat-dialog-actions>
</form>




import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-bank-info-alert-dialog',
  templateUrl: './bank-info-alert-dialog.component.html',
  styleUrls: ['./bank-info-alert-dialog.component.scss']
})
export class BankInfoAlertDialogComponent implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<BankInfoAlertDialogComponent>
  ) { 
    console.log("data", data);
  }

  ngOnInit(): void {
  }

}

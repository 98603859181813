import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig} from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { AddEditLinkDialogComponent } from './components/add-edit-link-dialog/add-edit-link-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { IconModule } from '@visurel/iconify-angular';
import { ImageViewerDialogComponent } from './components/image-viewer-dialog/image-viewer-dialog.component'; 
import { NoSpacesDirective } from './directives/no-spaces.directive';
import { BankInfoAlertDialogComponent } from './bank-info-alert-dialog/bank-info-alert-dialog.component';
import { RouterModule } from '@angular/router'; 

@NgModule({
    imports: [
        CommonModule,
        LayoutModule,
        FormsModule, 
        ReactiveFormsModule,
        FlexLayoutModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
        MatSelectModule,
        MatMenuModule,
        RouterModule,
        IconModule,
        MatDividerModule,
        MatChipsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatSnackBarModule
    ],
  exports: [
    LayoutModule,  
    BankInfoAlertDialogComponent
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
          duration: 3000,
          panelClass: ['snackbar-dark'],
          horizontalPosition: 'right'
      } as MatSnackBarConfig
    },
      CookieService
  ],
  declarations: [ConfirmationDialogComponent, AddEditLinkDialogComponent, ImageViewerDialogComponent, NoSpacesDirective, BankInfoAlertDialogComponent]
})
export class VexModule {
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {DocumentUploadComponent} from './document-upload/document-upload.component';
import {VexRoutes} from '../../@vex/interfaces/vex-route.interface'; 


const routes: VexRoutes = [
  {
    path: '',
    component: DocumentUploadComponent
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentUploadLayoutRoutingModule {
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq/faq.component';
import { MatRippleModule } from '@angular/material/core';
import { IconModule } from '@visurel/iconify-angular';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { SafeModule } from 'src/@vex/pipes/safe/safe.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { ContainerModule } from 'src/@vex/directives/container/container.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VexRoutes } from 'src/@vex/interfaces/vex-route.interface';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';

// const routes: VexRoutes = [
//   {
//     path: '',
//     component: FaqComponent,
//     data: {
//       toolbarShadowEnabled: true
//     }
//   },
// ]

@NgModule({
  // declarations: [FaqComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    // RouterModule.forChild(routes),
    ContainerModule,
    SecondaryToolbarModule,
    BreadcrumbsModule,
    MatSnackBarModule,
    SafeModule,
    MatExpansionModule,
    PageLayoutModule,
    FlexModule,
    MatIconModule,
    IconModule,
    MatRippleModule
  ]
})
export class FaqModule { }

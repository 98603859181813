import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { AdminProfileModel } from '../interfaces/admin-profile.model';
import icClose from '@iconify/icons-ic/twotone-close';
import icPerson from '@iconify/icons-ic/twotone-person';
import icAdd from '@iconify/icons-ic/twotone-add';
import {AuthServiceLocal} from '../../../../@vex/services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Messages} from '../../../../@vex/utils/message';



@Component({
  selector: 'vex-customer-create-update',
  templateUrl: './bank-detail-create-update.component.html',
  styleUrls: ['./bank-detail-create-update.component.scss']
})
export class BankDetailCreateUpdateComponent implements OnInit {

  static id = 100;
  form: FormGroup;
  mode: 'create' | 'update' = 'create';
  icClose = icClose;
  icPerson = icPerson;
  icAdd = icAdd;
  message = Messages;


  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private dialogRef: MatDialogRef<BankDetailCreateUpdateComponent>,
              private fb: FormBuilder,
              private auth: AuthServiceLocal,
              private snackbar: MatSnackBar){
  }

  ngOnInit() {
   if (this.defaults) {
      this.mode = 'update';
    } else {
      this.defaults = {} as AdminProfileModel;
    }

   this.form = this.fb.group({
      amount: [this.defaults.amount || ''],
      remarks: [this.defaults.remarks || ''],
    });
  }

  /**
   * On form submit
   * */
  save() {
    if (this.form.invalid){
        return false;
    } else {
        if (this.mode === 'create') {
            this.requestWithdrawal();
        } else if (this.mode === 'update') {
            // this.updateNgo();
        }
    }
  }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }

    /**
     * function call when submit request
     */
  requestWithdrawal() {
    const postData = {
        amount: Number(this.form.value.amount),
        remarks: this.form.value.remarks,
        payment_type: 1
    };
    this.auth.requestWithdrawal(postData).subscribe((response: any) => {
        if (response.response === 1){
            // this.store.setItem('loggedUser', response.data);
            this.snackbar.open(response.message, 'Succes');
        } else{
            this.snackbar.open(response.message, 'Error');
        }
    });
    this.dialogRef.close(postData);
  }

}

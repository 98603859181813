<vex-page-layout>
    <vex-secondary-toolbar current="Total Withdrawal"  class="pb-16">
        <vex-breadcrumbs  @fadeInRight class="flex-auto"></vex-breadcrumbs>

        <h4 class="m-3" fxLayout="row" fxLayoutAlign="center center">
            <b class="mr-3">Total Amount:</b>
            <div fxLayout="row" fxLayoutAlign="center center">
<!--                <mat-icon [icIcon]="icDolor"></mat-icon>-->
                <span class="mr-1" *ngIf="AdminDetail.country.toLowerCase() === country.Ghana">
                    {{currency.Ghana}}
                </span>
                <span class="mr-1" *ngIf="AdminDetail.country.toLowerCase() === country.Nigeria">
                    {{currency.Nigeria}}
                </span>
                <span class="mr-1" *ngIf="AdminDetail.country.toLowerCase() === country.US">
                    {{currency.US}}
                </span>
                {{totalAmountAvailable}}
            </div>
        </h4>
    </vex-secondary-toolbar>

  <vex-page-layout-content [class.px-gutter]="layoutCtrl.value === 'fullwidth'">
    <div class="card overflow-auto">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutAlign="start center">
        <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none" fxHide.xs>
          <span *ngIf="selection.isEmpty()"> Withdrawal </span>
          <span *ngIf="selection.hasValue()">{{ selection.selected.length }}
            Category <span *ngIf="selection.selected.length > 1">s</span> selected</span>
        </h2>

        <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r" fxFlex="none">
          <button color="primary" mat-icon-button matTooltip="Another action" type="button">
            <mat-icon [icIcon]="icFolder"></mat-icon>
          </button>
        </div>

        <div class="bg-card rounded-full border px-4"
             fxFlex="400px"
             fxFlex.lt-md="auto"
             fxHide.xs
             fxLayout="row"
             fxLayoutAlign="start center">
          <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
          <input [formControl]="searchCtrl"  
                 class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                 placeholder="Search..."
                 type="search">
        </div>        

        <span fxFlex></span>

        <button class="ml-4" fxFlex="none" fxHide.gt-xs mat-icon-button type="button">
          <mat-icon [icIcon]="icSearch"></mat-icon>
        </button>

          <button (click)="openWithdrawalPopup()" class="ml-4" color="primary" mat-raised-button type="button">
              Request Withdrawal
          </button>

      </div>

      <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
       <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>

            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                <span *ngIf="column.property !=='createdDate' && column.property !== 'isAdminApproved' && column.property !=='paymentStatus'">
                    {{ row[column.property] }}
                 </span>
                <span *ngIf="(column.property =='isAdminApproved' || column.property =='paymentStatus') && (row['isAdminApproved'] === 0 || row['paymentStatus'] === 0)">
                    Pending
                </span>
                <span *ngIf="(column.property =='isAdminApproved' || column.property =='paymentStatus') && (row['isAdminApproved'] === 1 || row['paymentStatus'] === 1)">
                    Approved
                </span>
                <span *ngIf="column.property =='createdDate'">
                    {{ row[column.property] | date: 'MMM d, y' }}
                </span>
            </td>

          </ng-container>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
<!--            <button (click)="$event.stopPropagation()"-->
<!--                    [matMenuTriggerData]="{ customer: row }"-->
<!--                    [matMenuTriggerFor]="actionsMenu"-->
<!--                    mat-icon-button-->
<!--                    type="button">-->
<!--              <mat-icon [icIcon]="icMoreHoriz"></mat-icon>-->
<!--            </button>-->
          </td>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr
            *matRowDef="let row; columns: visibleColumns;"
            @fadeInUp
            class="hover:bg-hover trans-ease-out cursor-pointer"
            mat-row></tr>
      </table>
      <div *ngIf="!this.dataSource?.data?.length" class="text-center text-gray py-12">No data Found</div>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" class="sticky left-0"></mat-paginator>
    </div>
  </vex-page-layout-content>
</vex-page-layout>

<!--<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">-->
<!--  <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"-->
<!--          class="checkbox-item mat-menu-item">-->
<!--    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">-->
<!--      {{ column.label }}-->
<!--    </mat-checkbox>-->
<!--  </button>-->
<!--</mat-menu>-->
<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
<!--    <button (click)="updateNgo(customer)" mat-menu-item>-->
<!--      <mat-icon [icIcon]="icEdit"></mat-icon>-->
<!--      <span>Modify</span>-->
<!--    </button>-->
<!--    <button  (click)="DeleteConfirmBox(customer)" mat-menu-item>-->
<!--      <mat-icon   [icIcon]="icDelete"></mat-icon>-->
<!--      <span>Delete</span>-->
<!--    </button>-->
  </ng-template>
</mat-menu>



import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vex-document-upload-layout',
  templateUrl: './document-upload-layout.component.html',
  styleUrls: ['./document-upload-layout.component.scss']
})
export class DocumentUploadLayoutComponent implements OnInit {

  constructor() { } 

  ngOnInit(): void {

  }

}
